import React from 'react';

import Modal from 'react-bootstrap/Modal';
import FormComponent from '../Form/Form';

const TarifsPopup = ({ showModal, closeModal }) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
      onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Форма обратного звонка</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormComponent flag={true} />
      </Modal.Body>
    </Modal>
  );
};

export default TarifsPopup;
