import React, { useEffect, useState } from 'react';
import style from './Module.module.scss';

import Popup from '../Popup/Popup';
import FormComponent from '../Form/Form';
import { Button } from 'react-bootstrap';

const Module = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [flag] = useState(props.imgFlag);
  const [content, setContent] = useState([]);

  useEffect(() => {
    if (Array.isArray(props.textContent)) {
      setContent(props.textContent);
    }
  }, [props.textContent]);

  return (
    <section
      className={style.module_wrapper}
      style={{ backgroundImage: `url(${props.bgImage})`, backgroundColor: `${props.bgColor}` }}>
      <div className="container">
        <div className={style.wrapper}>
          <div className={`${style.image_block} ${props.flexItem} ${props.textAlignEnd}`}>
            {flag === true ? <></> : <img src={props.img} alt={props.alt} />}
          </div>
          <div className={`${style.content} ${props.flexItem}`}>
            <p className={style.headline_text}>{props.headlineText}</p>
            <h2 className={style.title}>{props.title}</h2>
            {content.map((item, i) => (
              <p key={i} className={style.text}>
                {item.text}
              </p>
            ))}
            <p className={style.text}>{props.text}</p>
            <div>
              <Button
                className={style.formBtn}
                variant="primary"
                onClick={() => setModalShow(true)}>
                {props.btnText}
              </Button>
            </div>
            <Popup
              contentComponent={<FormComponent flag={true} />}
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Module;
