import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ROUTES } from '../utils/routes';
import Header from '../Components/Header/Header';
import HomePage from '../Pages/HomePage/HomePage';
import Footer from '../Components/Footer/Footer';
import News from '../Pages/News/News';
import About from '../Pages/AboutPage/About';
import Roznitcya from '../Pages/Roznitcya/Roznitcya';
import Unf from '../Pages/Unf/Unf';
import Zup from '../Pages/Zup/Zup';
import Oblako from '../Pages/Oblako/Oblako';
import Licenziy from '../Pages/Licenziy/Licenziy';
import Its from '../Pages/ITS/Its';
import NotFound from '../Pages/NotFound/NotFound';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.HOME} element={<Header />}>
          <Route index element={<HomePage />} />
          <Route path={ROUTES.NEWS} element={<News />} />
          <Route path={ROUTES.ABOUT} element={<About />} />
          <Route path={ROUTES.ROZNITCYA} element={<Roznitcya />} />
          <Route path={ROUTES.UNF} element={<Unf />} />
          <Route path={ROUTES.ZUP} element={<Zup />} />
          <Route path={ROUTES.V_OBLAKE} element={<Oblako />} />
          <Route path={ROUTES.LICENZIY} element={<Licenziy />} />
          <Route path={ROUTES.ITS} element={<Its />} />
          <Route path={ROUTES.NOTFOUND} element={<NotFound />} />
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default AppRoutes;
