import React from 'react';
import style from './PayBox.module.scss';
import payboxQr from '../../Assets/Icons/qr-image.svg';
import { Link } from 'react-router-dom';

const PayBox = () => {
  return (
    <div className={style.paybox}>
      <h1 className="text-center">
        Чтобы сделать оплату наведите камеру телефона на qr код или перейдите по ссылке ниже!
      </h1>
      <img src={payboxQr} alt="paybox qr" />
      <div>
        <Link
          to={'https://customer.freedompay.money/links?slug=MwMXs'}
          target="_blank"
          className={`${style.link} btn btn-primary`}>
          Перейти
        </Link>
      </div>
    </div>
  );
};

export default PayBox;
