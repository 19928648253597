import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';
import FormField from 'react-bootstrap/Form';
import TextMask from 'react-text-mask';
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';
import { reCaptchaExecute } from 'recaptcha-v3-react-function-async';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^([^0-9]*)$/, 'Имя не должно содержать цифры')
    .required('Обязательное поле'),
  email: Yup.string().email('Неверный формат email').required('Обязательное поле'),
  phone: Yup.string()
    .required('Обязательное поле')
    .test('phone', 'Неверный формат номера', (value) => {
      if (!value) return false;
      const phoneNumber = parsePhoneNumberFromString(value, 'KZ');
      return phoneNumber ? phoneNumber.isValid() : false;
    }),
  message: Yup.string(),
});

const validationSchema2 = Yup.object().shape({
  name: Yup.string()
    .matches(/^([^0-9]*)$/, 'Имя не должно содержать цифры')
    .required('Обязательное поле'),
  phone: Yup.string()
    .required('Обязательное поле')
    .test('phone', 'Неверный формат номера', (value) => {
      if (!value) return false;
      const phoneNumber = parsePhoneNumberFromString(value, 'KZ');
      return phoneNumber ? phoneNumber.isValid() : false;
    }),
});

const FormComponent = ({ flag }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [access, setAccess] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [accessText, setAccessText] = useState('');
  const [errors, setErrors] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  // const reqUrl = 'http://localhost:5000';
  const reqUrl = process.env.REACT_APP_REQ_URL;

  const onSubmit = async (values, url) => {
    setIsFetching(true);

    let gtoken = { token: await reCaptchaExecute(process.env.REACT_APP_RECAPTCHA_SITE_KEY) };

    const data = url;
    const combineData = { ...data, ...gtoken };
    const URL = values;

    try {
      const response = await axios.post(URL, combineData);
      console.log(response);

      if (response.status === 200) {
        setAccess(true);
        setAccessText('Данные успешно отправлены');
        setIsFetching(false);
      }
    } catch (error) {
      setErrors('Произошла ошибка при отправке данных');
      setIsVisible(true);
      setAccess(false);
      setIsFetching(false);
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const normalizePhoneNumber = (value) => {
    const asYouType = new AsYouType('KZ');
    asYouType.input(value);
    return asYouType.formattedOutput;
  };

  if (isVisible === true) {
    setTimeout(() => {
      setIsVisible(false);
    }, 3000);
  }

  if (flag === true) {
    return (
      <Formik
        initialValues={{
          name: '',
          email: '',
          phone: '',
          message: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(`${reqUrl}/send-email`, values)}>
        <Form>
          <FormField.Group className="mb-3" controlId="name">
            <FormField.Label>Ваше имя</FormField.Label>
            <Field
              type="text" // Указываем тип "text" для имени
              name="name"
              id="name"
              className="form-control"
              placeholder="Введите ваше имя"
              autoComplete="on"
            />
            <ErrorMessage name="name" component="div" className="text-danger" />
          </FormField.Group>

          <FormField.Group className="mb-3" controlId="email">
            <FormField.Label>Ваша почта</FormField.Label>
            <Field
              type="email"
              name="email"
              id="email"
              className="form-control"
              placeholder="Введите адрес почты"
              autoComplete="on"
            />
            <FormField.Text className="text-muted">
              Мы никогда не передадим ваш электронный адрес кому-либо еще.
            </FormField.Text>
            <ErrorMessage name="email" component="div" className="text-danger" />
          </FormField.Group>

          <FormField.Group className="mb-3" controlId="phone">
            <FormField.Label>Номер телефона</FormField.Label>
            <Field name="phone">
              {({ field }) => (
                <TextMask
                  {...field}
                  mask={[
                    '+',
                    '7',
                    ' ',
                    '(',
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                  ]}
                  guide={false}
                  placeholder="+7"
                  type="tel"
                  id="phone"
                  autoComplete="on"
                  onBlur={(e) => {
                    field.onBlur(e);
                    field.onChange({
                      target: { name: 'phone', value: normalizePhoneNumber(e.target.value) },
                    });
                  }}
                  className="form-control"
                />
              )}
            </Field>
            <FormField.Text className="text-muted">
              Мы не будем делиться вашим номером телефона с кем-либо ещё.
            </FormField.Text>
            <ErrorMessage name="phone" component="div" className="text-danger" />
          </FormField.Group>

          <FormField.Group className="mb-3" controlId="message">
            <FormField.Label>Комментарий к заказу</FormField.Label>
            <Field as="textarea" name="message" id="message" className="form-control" rows={3} />
          </FormField.Group>

          <FormField.Group className="mb-3" controlId="formBasicCheckbox">
            <FormField.Check
              checked={isChecked}
              onChange={handleCheckboxChange}
              type="checkbox"
              label="Я согласен"
            />
          </FormField.Group>

          <Button disabled={!isChecked || isFetching} variant="primary" type="submit">
            {isFetching ? 'Отправка...' : 'Отправить'}
          </Button>

          {isVisible ? (
            <div className="alert alert-danger d-flex mt-2 align-items-center" role="alert">
              <FaExclamationTriangle
                className="bi flex-shrink-0 me-2"
                role="img"
                aria-label="Danger:"
              />
              <div>{errors}</div>
            </div>
          ) : (
            <></>
          )}

          {access ? (
            <div className="alert alert-success d-flex mt-2 align-items-center" role="alert">
              <FaCheckCircle className="bi flex-shrink-0 me-2" role="img" aria-label="Success:" />
              <div>{accessText}</div>
            </div>
          ) : (
            <></>
          )}
        </Form>
      </Formik>
    );
  } else {
    return (
      <Formik
        initialValues={{
          name: '',
          phone: '',
        }}
        validationSchema={validationSchema2}
        onSubmit={(values) => onSubmit(`${reqUrl}/send-form`, values)}>
        <Form className="col-md-6 mx-auto">
          <FormField.Group className="mb-3" controlId="name">
            <FormField.Label>Ваше имя</FormField.Label>
            <Field
              type="text" // Указываем тип "text" для имени
              name="name"
              id="name"
              className="form-control"
              placeholder="Введите ваше имя"
              autoComplete="on"
            />
            <ErrorMessage name="name" component="div" className="text-danger" />
          </FormField.Group>

          <FormField.Group className="mb-3" controlId="phone">
            <FormField.Label>Номер телефона</FormField.Label>
            <Field name="phone">
              {({ field }) => (
                <TextMask
                  {...field}
                  mask={[
                    '+',
                    '7',
                    ' ',
                    '(',
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                  ]}
                  guide={false}
                  placeholder="Введите номер телефона"
                  type="tel"
                  id="phone"
                  autoComplete="on"
                  onBlur={(e) => {
                    field.onBlur(e);
                    field.onChange({
                      target: { name: 'phone', value: normalizePhoneNumber(e.target.value) },
                    });
                  }}
                  className="form-control"
                />
              )}
            </Field>
            <FormField.Text className="text-muted">
              Мы не будем делиться вашим номером телефона с кем-либо ещё.
            </FormField.Text>
            <ErrorMessage name="phone" component="div" className="text-danger" />
          </FormField.Group>

          <FormField.Group className="mb-3" controlId="formBasicCheckbox">
            <FormField.Check
              checked={isChecked}
              onChange={handleCheckboxChange}
              type="checkbox"
              label="Я согласен"
            />
          </FormField.Group>

          <Button
            className="float-end"
            disabled={!isChecked || isFetching}
            variant="primary"
            type="submit">
            {isFetching ? 'Отправка...' : 'Отправить'}
          </Button>

          {isVisible ? (
            <div className="alert alert-danger d-flex mt-2 align-items-center" role="alert">
              <FaExclamationTriangle
                className="bi flex-shrink-0 me-2"
                role="img"
                aria-label="Danger:"
              />
              <div>{errors}</div>
            </div>
          ) : (
            <></>
          )}

          {access ? (
            <div className="alert alert-success d-flex mt-2 align-items-center" role="alert">
              <FaCheckCircle className="bi flex-shrink-0 me-2" role="img" aria-label="Success:" />
              <div>{accessText}</div>
            </div>
          ) : (
            <></>
          )}
        </Form>
      </Formik>
    );
  }
};

export default FormComponent;
