import React from 'react';
import './DarkOverlay.scss';

const DarkOverlay = ({ showOverlay, closeMenu }) => {
  return showOverlay ? (
    <div
      className="dark-overlay"
      onClick={closeMenu} // Добавьте этот обработчик, чтобы закрыть меню при клике на фон
    ></div>
  ) : null;
};

export default DarkOverlay;
