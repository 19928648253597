import React from 'react';

import Modal from 'react-bootstrap/Modal';

const Popup = ({ contentComponent, show, onHide, paybox }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {paybox ? 'Онлайн оплата' : 'Форма обратного звонка'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{contentComponent}</Modal.Body>
    </Modal>
  );
};

export default Popup;
