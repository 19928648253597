/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import style from './About.module.scss';

import PhoneNumber from '../../Components/UI/PhoneNumber/PhoneNumber';
import Module from '../../Components/Module/Module';

import Module_1_Image from '../../Assets/Images/franch.png';
import Module_1_SectionBgImage from '../../Assets/Images/wave-2.svg';

import sertifikat from '../../Assets/Images/sertifikat.jpg';
import Title from '../../Components/UI/Title/Title';
import FormComponent from '../../Components/Form/Form';

const About = () => {
  const textContent = [
    {
      text: 'Компания «IT Doc» основана в 2015 году. Мы оказываем услуги по программе 1С, а также занимаемся ИТ-аутсорсингом и веб-разработкой.',
    },
    {
      text: 'Начиная с основания компании мы помогаем предприятиям Алматы и Алматинской области работать не отвлекаясь на перебои работы компьютеров и программ.',
    },
    {
      text: 'За эти годы мы собрали большой опыт, это помогает нам решать поставленные задачи быстро и оперативно.',
    },
  ];

  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://itdoc.kz/about" />
        <title>О компании</title>
        <meta
          name="description"
          content="Компания IT Doc является 1С франчайзи, мы предоставляем такие услуги как Сопровождение 1С, консультация"
        />
        <meta property="og:title" content="О компании" />
        <meta
          property="og:description"
          content="Компания IT Doc является 1С франчайзи, мы предоставляем такие услуги как Сопровождение 1С, консультация"
        />
        <meta name="keywords" content="IT Doc, 1C Франчайзи, Сопровождение 1С" />
      </Helmet>
      <div className={style.wrapper_about}>
        <div className="content container">
          <div className={style.wrapper}>
            <div className={style.item_1}>
              <div className={style.content}>
                <h4 className="h4">Контактная информация</h4>
                <div className={style.company_name}>IT Doc</div>
                <Link className={style.link} to={'https://go.2gis.com/9mog7'}>
                  г.Алматы ул.Наурызбай Батыра 17/1
                </Link>
                <PhoneNumber
                  list_style="list_style-phone"
                  item_style="item_style"
                  link_style="link_style"
                  item_link="tel:+77089725838"
                  text="+7 708 972 58 38"
                />
              </div>
            </div>
            <div className={style.item_2}>
              <div className={style.map}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2905.233380920829!2d76.93125727660367!3d43.26748627729172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38836ea69f2b1ff7%3A0x1f44695f49a09ec0!2z0JHQpiDQodC40YDQuNGD0YE!5e0!3m2!1sru!2skz!4v1695981367914!5m2!1sru!2skz"
                  width="100%"
                  height="450"
                  style={{ border: '0' }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
        <Module
          img={Module_1_Image}
          bgImage={Module_1_SectionBgImage}
          flexItem="flex-item"
          textAlignEnd="text-align-end"
          alt="О компании"
          headlineText="Слаженная командная работа говорит о нас"
          title="НЕСКОЛЬКО СЛОВ О НАС"
          textContent={textContent}
          btnText="Связаться с нами"
        />
        <div className={`${style.img} container`}>
          <img src={sertifikat} alt="Sertitfikat" />
        </div>
        <div style={{ marginBottom: '70px' }} className="container">
          <Title
            title="Есть вопросы или не уверены, что выбрать?"
            subtitle="Пожалуйста, заполните форму, и наш специалист свяжется с вами в самое ближайшее время, ответит на ваши вопросы и предложит наилучшие решения."
          />
          <FormComponent flag={false} />
        </div>
      </div>
    </div>
  );
};

export default About;
