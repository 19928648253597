import React from 'react';

import style from './Hero.module.scss';

const Hero = (props) => {
  return (
    <div className={style.section_wrapper} style={{ backgroundImage: `url(${props.bgImage})` }}>
      <section className="container">
        <div className={style.wrapper}>
          <div className={style.img}>
            <img src={props.img} alt={props.alt} />
          </div>
          <div className={style.content}>
            <div className={style.inner}>
              <h1 className={style.title}>{props.title}</h1>
              <p className={style.text}>{props.text}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
