import React from 'react';
import style from './Its.module.scss';
import { Helmet } from 'react-helmet';

import Title from '../../Components/UI/Title/Title';
import FormComponent from '../../Components/Form/Form';
import Hero from '../../Components/UI/Hero/Hero';
import Module from '../../Components/Module/Module';
import Table from '../../Components/Table/Table';
import Tarifs from '../../Components/Tarifs/Tarifs';

import { FaRegCircleCheck } from 'react-icons/fa6';
import { FaRegCircleXmark } from 'react-icons/fa6';

import its_1C from '../../Assets/Images/1s_its.png';
import its_1C_hero from '../../Assets/Images/1s-its-hero.png';

const Its = () => {
  const tableData = [
    {
      row: {
        rowId: 'Доступ к сайту обновлений portal.1c.ru',
        row_1: <FaRegCircleCheck className="FaRegCircleCheck" />,
        row_2: <FaRegCircleCheck className="FaRegCircleCheck" />,
        row_3: <FaRegCircleCheck className="FaRegCircleCheck" />,
      },
    },
    {
      row: {
        rowId: 'Сопровождение баз 1С',
        row_1: <FaRegCircleXmark className="FaRegCircleXmark" />,
        row_2: <FaRegCircleCheck className="FaRegCircleCheck" />,
        row_3: <FaRegCircleCheck className="FaRegCircleCheck" />,
      },
    },
    {
      row: {
        rowId: 'Количество баз данных',
        row_1: '1',
        row_2: 'до 2',
        row_3: 'до 10',
      },
    },
    {
      row: {
        rowId: 'Линия консультации 1С',
        row_1: <FaRegCircleXmark className="FaRegCircleXmark" />,
        row_2: '4 часа в неделю',
        row_3: '12 часов в неделю',
      },
    },
    {
      row: {
        rowId: 'Установка и обновление СОНО',
        row_1: <FaRegCircleXmark className="FaRegCircleXmark" />,
        row_2: '1 рабочее место',
        row_3: '3 рабочих мест',
      },
    },
    {
      row: {
        rowId: 'Обновления баз вне тарифа',
        row_1: '8000/база/релиз',
        row_2: '5600 база/релиз',
        row_3: '4000 база/релиз',
      },
    },
    {
      row: {
        rowId: 'Настройка ЭСФ, Банк-Клиент, Пользователей 1С',
        row_1: <FaRegCircleXmark className="FaRegCircleXmark" />,
        row_2: <FaRegCircleCheck className="FaRegCircleCheck" />,
        row_3: <FaRegCircleCheck className="FaRegCircleCheck" />,
      },
    },
    {
      row: {
        rowId: 'Работы 1С Специалиста',
        row_1: '13 000 тг/час',
        row_2: '11000 тг/час',
        row_3: '9000 тг/час',
      },
    },
    {
      row: {
        rowId: 'Система 1С:ИТС Казахстан its.1c.kz',
        row_1: 'its.1c.kz ограниченный доступ',
        row_2: 'its.1c.kz полный доступ',
        row_3: 'its.1c.kz полный доступ',
      },
    },
    {
      row: {
        rowId: '1С-Коннект программа для мгновенного соединения с нужным специалистом 1С',
        row_1: <FaRegCircleXmark className="FaRegCircleXmark" />,
        row_2: '2 пользователя',
        row_3: 'до 5-ти пользователей',
      },
    },
    {
      row: {
        rowId: '1С-Линк сервис доступа к базе из любой точки мира',
        row_1: <FaRegCircleXmark className="FaRegCircleXmark" />,
        row_2: '2 базы',
        row_3: 'до 10 баз',
      },
    },
  ];

  const tableHeaderData = [
    {
      colId: 'Наименование услуг входящих в тарифный план',
      col_1: 'ИТС Техно',
      col_2: 'ИТС Проф',
      col_3: 'ИТС Корп',
    },
  ];

  const tablePriceHeaderData = [
    {
      colId: 'Стоимость услуг',
      col_1: 'ИТС Техно',
      col_2: 'ИТС Проф',
      col_3: 'ИТС Корп',
    },
  ];

  const tablePriceData = [
    {
      row: {
        rowId: '3 месяца ',
        row_1: <FaRegCircleXmark className="FaRegCircleXmark" />,
        row_2: '36 000 тг',
        row_3: '90 000 тг',
      },
    },
    {
      row: {
        rowId: '6 месяцев',
        row_1: '34 000 тг',
        row_2: '69 000 тг',
        row_3: '170 000 тг',
      },
    },
    {
      row: {
        rowId: '12 месяцев ',
        row_1: '64 000 тг',
        row_2: '130 000 тг',
        row_3: '325 000 тг',
      },
    },
  ];

  return (
    <div className={`${style.tarifsPage_section} content`}>
      <Helmet>
        <link rel="canonical" href="https://itdoc.kz/its" />
        <title>1С ИТС</title>
        <meta
          name="description"
          content="1С ИТС это - информационно технологическое сопровождение, Наша компания является официальным партнером 1С. Звоните ☎+7 (708) 972-58-38."
        />
        <meta
          property="og:description"
          content="1С ИТС это - информационно технологическое сопровождение, Наша компания является официальным партнером 1С. Звоните ☎+7 (708) 972-58-38."
        />
        <meta name="keywords" content="1С ИТС, информационно технологическое сопровождение" />
      </Helmet>

      <Hero
        img={its_1C_hero}
        title="1С:ИТС"
        text="Консультация, Сопровождение, Обновление"
        alt="1С:ИТС"
      />
      <Module
        img={its_1C}
        alt="Что такое ИТС от IT Doc"
        headlineText=""
        title="Что такое ИТС от IT Doc"
        text="Пользователи ИТС могут воспользоваться удобными сервисами, повышающими оперативность работы и создающими комфортные условия для бизнес-процессов, в частности, документооборота и взаимодействия с клиентами через корпоративный портал компании. Специалисты сервисной организации решают возникающие проблемы, консультируют сотрудников клиентов, работающих в 1С, по техническим вопросам, предоставляют пошаговые инструкции по подготовке бухгалтерской и налоговой отчетности и при необходимости проводят обучение сотрудников компании-клиента, направленное на повышение эффективности за счет правильного управления работой."
        btnText="Купить"
      />
      <Title title="Тарифы 1С:ИТС" />
      <div className={style.mobile_tarifs}>
        <Tarifs />
      </div>
      <div className={style.desktop_tarifs}>
        <Table flag={true} data={tableData} dataHeader={tableHeaderData} />
        <Table flag={true} data={tablePriceData} dataHeader={tablePriceHeaderData} />
      </div>
      <div style={{ marginBottom: '70px' }} className="container">
        <Title
          title="Есть вопросы или не уверены, что выбрать?"
          subtitle="Пожалуйста, заполните форму, и наш специалист свяжется с вами в самое ближайшее время, ответит на ваши вопросы и предложит наилучшие решения."
        />
        <FormComponent flag={false} />
      </div>
    </div>
  );
};

export default Its;
