import React from 'react';

import Hero from '../../Components/UI/Hero/Hero';
import Module from '../../Components/Module/Module';
import Alert from '../../Components/Alert/Alert';
import Title from '../../Components/UI/Title/Title';
import FormComponent from '../../Components/Form/Form';
import List from '../../Components/List/List';
import Price from '../../Components/Price/Price';

import HeroIMage from '../../Assets/Images/zup-bazovaya.png';

import Module_1_Image from '../../Assets/Images/sheme_for_zup_.jpg';
import Module_1_SectionBgImage from '../../Assets/Images/wave-1.svg';

import Module_2_Image from '../../Assets/Images/content_img.png';
import { Helmet } from 'react-helmet';

const Zup = () => {
  const listData = [
    {
      title: '1С-Отчетность:',
      subtitle:
        'для подготовки и сдачи регламентированной отчетности из программ «1С» во все контролирующие органы',
    },
    {
      title: 'Информационная система 1С:ИТС:',
      subtitle:
        'Получить справочную информацию по кадровому учету, расчету заработной платы и операциям в программах "1С:Зарплата и управление персоналом 8"; Информационная система "1С:ИТС" включает в себя Информационная система 1С:ИТС включает: Справочник кадровика, новости, комментарии и консультации экспертов по вопросам кадрового учета, пошаговые процедуры ведения кадрового учета и кадровых расчетов в программе "1С", подготовки и сдачи отчетности в контролирующие органы;',
    },
    {
      title: '1С:ДиректБанк:',
      subtitle:
        'Обмен информацией по зарплатным проектам может осуществляться в электронном виде непосредственно с банком. Можно создавать прикладные файлы для открытия и закрытия лицевых счетов и учета заработной платы, начисляемой сотрудникам. Такие файлы могут быть созданы по универсальным стандартам, поддерживаемым многими банками.',
    },
  ];

  const content = [
    {
      img: HeroIMage,
      title: 'ОБЛАЧНАЯ 1С',
      subtitle: 'НА 1-2 ПОЛЬЗОВАТЕЛЯ',
      price: '4 600 тг/мес.',
      decs: `
        Полнофункциональная версия 1С.
        Работает через Интернет в облаке.
        Стоимость включает сопровождение-обновление.
      `,
    },
    {
      img: HeroIMage,
      title: 'ПРОФ',
      subtitle: 'НА 1 ПОЛЬЗОВАТЕЛЯ',
      price: '109 000тг',
      decs: 'Универсальная программа, может быть адаптирована к любым особенностям учета.',
    },
  ];

  return (
    <div className="content">
      <Helmet>
        <link rel="canonical" href="https://itdoc.kz/zup" />
        <title>1С ЗУП</title>
        <meta
          name="description"
          content={`1С ЗУП (Зарплата и Управление Персоналом): надежное программное решение для автоматизации учета заработной платы и управления персоналом. Оптимизируйте процессы управления кадрами и обеспечьте точную и своевременную выплату зарплаты с помощью 1С ЗУП.`}
        />
        <meta
          property="og:description"
          content={`1С ЗУП (Зарплата и Управление Персоналом): надежное программное решение для автоматизации учета заработной платы и управления персоналом. Оптимизируйте процессы управления кадрами и обеспечьте точную и своевременную выплату зарплаты с помощью 1С ЗУП.`}
        />
        <meta
          name="keywords"
          content="1С ЗУП, Зарплата и управление персоналом, Управление кадрами, Автоматизация учета заработной платы, Управление персоналом, Учет рабочего времени, Программа для учета ЗУП, Управление зарплатой"
        />
      </Helmet>
      {/*  */}
      <Hero
        img={HeroIMage}
        // bgImage={Hero_Bg_IMage}
        title="1С ЗУП 8.3"
        text={`"1С:Зарплата и управление персоналом 8.3" (сокращенно "1С:ЗУП") - это одна из наиболее простых и доступных программ для учета кадров, расчета заработной платы и управления персоналом.`}
        alt="1С ЗУП"
      />
      {/*  */}
      <Module
        img={Module_1_Image}
        flexItem="flex-item"
        textAlignEnd="text-align-end"
        alt="«1С:Зарплата и управление персоналом 8»"
        headlineText="1С:ЗУП 8.3 - Ваш Гид в Мире Зарплаты и Кадрового Управления"
        title="«1С:Зарплата и управление персоналом 8»"
        text="Эта программа успешно применяется в службах управления персоналом и бухгалтериях предприятий, а также в других подразделениях, заинтересованных в эффективной организации работы сотрудников. Она предназначена для управления человеческими ресурсами коммерческих предприятий различного масштаба, обеспечивая соблюдение законодательных требований и эффективное управление персоналом."
        btnText="Купить"
      />
      {/*  */}
      <Module
        img={Module_2_Image}
        bgImage={Module_1_SectionBgImage}
        alt="ЗУП"
        title="Где применяется «1С:ЗУП»?"
        text={`1С:Зарплата и управление персоналом 8.3" используется в отделах кадров и бухгалтерии. Но этим функции программы не ограничиваются: ЗУП используется во многих компаниях, занимающихся организацией качественной работы с персоналом и ведением кадровой политики.`}
        btnText="Купить"
      />
      {/*  */}
      <Alert
        title="Дополнительные возможности и подключаемые сервисы"
        text='"1С:Кабинет сотрудника". Мы рекомендуем использовать сервис "1С:Кабинет сотрудника" для мобильного взаимодействия сотрудников с бухгалтерией по кадровым вопросам!'
        bottom_text="Сотрудники могут проверять информацию о заработной плате, остатках отпусков, оформлять справки 2-НДФЛ, подавать заявления на отпуск и уведомления об отсутствии на рабочем месте в любое время из любой точки, где есть доступ к Интернету. Операции можно проводить в личном кабинете с помощью портала или мобильного приложения, не посещая бухгалтерию или отдел кадров."
      />
      {/*  */}
      <Price
        headline_title="1С:ЗУП ДЛЯ КАЗАХСТАНА"
        headline_subtitle="ЕСТЬ ДВЕ ВЕРСИИ:"
        alt="1С ЗУП"
        btn_text="Купить"
        products={content}
      />
      {/*  */}
      <List
        data={listData}
        title='Полезные сервисы для повышения производительности труда пользователей могут быть связаны с программой "1С:Зарплата и управление персоналом 8":'
      />
      {/*  */}
      <div style={{ marginBottom: '70px' }} className="container">
        <Title
          title="Есть вопросы или не уверены, что выбрать?"
          subtitle="Пожалуйста, заполните форму, и наш специалист свяжется с вами в самое ближайшее время, ответит на ваши вопросы и предложит наилучшие решения."
        />
        <FormComponent flag={false} />
      </div>
      {/*  */}
    </div>
  );
};

export default Zup;
