import React from 'react';

import Hero from '../../Components/UI/Hero/Hero';
import Module from '../../Components/Module/Module';
import FormComponent from '../../Components/Form/Form';
import Title from '../../Components/UI/Title/Title';
import Price from '../../Components/Price/Price';

import HeroIMage from '../../Assets/Images/unf1.png';
import Hero_Bg_IMage from '../../Assets/Images/wave-1.svg';

import ModuleIMage from '../../Assets/Images/unf22.png';
import ModuleIMage2 from '../../Assets/Images/unf3.png';
import { Helmet } from 'react-helmet';

const Unf = () => {
  const content = [
    {
      img: HeroIMage,
      title: 'ОБЛАЧНАЯ 1С',
      subtitle: 'НА 1-5 ПОЛЬЗОВАТЕЛЕЙ',
      price: '6 400 тг/мес.',
      decs: `
        Полнофункциональная версия 1С.
        Работает через Интернет в облаке.
        Стоимость включает сопровождение-обновление.
      `,
    },
    {
      img: HeroIMage,
      title: 'БАЗОВАЯ',
      subtitle: 'НА 1 ПОЛЬЗОВАТЕЛЯ',
      price: '36 300тг',
      decs: 'Готовое решение для небольших компании с любым видом деятельности',
    },
    {
      img: HeroIMage,
      title: 'ПРОФ',
      subtitle: 'НА 1 ПОЛЬЗОВАТЕЛЯ',
      price: '109 000тг',
      decs: 'Универсальная программа, может быть адаптирована к любым особенностям учета.',
    },
    {
      img: HeroIMage,
      title: 'СЕТЕВАЯ',
      subtitle: 'НА 5 ПОЛЬЗОВАТЕЛЕЙ',
      price: '203 000тг',
      decs: 'Комплект на 5 пользователей по выгодной цене.',
    },
  ];

  return (
    <div className="content">
      <Helmet>
        <link rel="canonical" href="https://itdoc.kz/unf" />
        <title>1С УНФ</title>
        <meta
          name="description"
          content={`1С УНФ (Управление Нашей Фирмой): комплексное программное решение для эффективного управления вашим предприятием. Оптимизируйте бизнес-процессы, финансовый учет и управление персоналом с помощью 1С УНФ.`}
        />
        <meta
          property="og:description"
          content={`1С УНФ (Управление Нашей Фирмой): комплексное программное решение для эффективного управления вашим предприятием. Оптимизируйте бизнес-процессы, финансовый учет и управление персоналом с помощью 1С УНФ.`}
        />
        <meta
          name="keywords"
          content="1С УНФ, Управление бизнесом, Управление фирмой, Программа для управления предприятием, Бизнес-процессы, Финансовый учет, Оптимизация бизнеса,"
        />
      </Helmet>
      {/*  */}
      <Hero
        img={HeroIMage}
        bgImage={Hero_Bg_IMage}
        title="1С УНФ 8.3"
        text={`1С Управление Нашей Фирмой (УНФ) 8.3: Современное Решение для Эффективного Бизнес-Управления`}
        alt="1С УНФ"
      />
      {/*  */}
      <Module
        img={ModuleIMage}
        alt="Бухгалтерия"
        headlineText="ТЕПЕРЬ РАБОТЫ БУДЕТ В ДВА РАЗА МЕНЬШЕ"
        title="Новый уровень ведения малого бизнеса"
        text="1С:Управление нашей фирмой – это простое комплексное решение для автоматизации бизнеса. В одной программе вы можете вести основные виды учета вашего бизнеса: Финансовый, Кадровый, Производственный. Он особенно популярен среди малого и микробизнеса, поскольку в максимальной степени сочетает в себе необходимый функционал и не требует дополнительного обучения – начать использовать его можно сразу, даже имея ограниченный опыт."
        btnText="Купить"
      />
      {/*  */}
      <Module
        img={ModuleIMage2}
        flexItem="flex-item"
        textAlignEnd="text-align-end"
        alt="МЫ ДАДИМ ВАМ 30 ДНЕЙ ДЛЯ ОЗНАКОМЛЕНИЯ"
        headlineText="«1С:УНФ» — комплексное решение. Он заменит десятки форм, документов и отчетов."
        title="Всё что нужно в одной программе УНФ"
        text="В программу включено все необходимое для управления вашей компанией: CRM, торговля и склад, сервис и инжиниринг, закупки и резервы, банк и касса, персонал и расчет заработной платы, производственно-монтажные операции, аналитические отчеты, налоговые платежи и сдача отчетности. Продукция строго соответствует законам и правилам."
        btnText="Купить"
      />
      {/*  */}
      <Price
        headline_title="1С:УНФ ДЛЯ КАЗАХСТАНА"
        headline_subtitle="ЕСТЬ ЧЕТЫРЕ ВЕРСИИ:"
        alt="1С УНФ"
        btn_text="Купить"
        products={content}
      />
      {/*  */}
      <div style={{ marginBottom: '70px' }} className="container">
        <Title
          title="Все еще думаете подходит ли вам программа 1С:УНФ?"
          subtitle="Пожалуйста, заполните форму, и наш специалист свяжется с вами в самое ближайшее время, ответит на ваши вопросы и предложит наилучшие решения."
        />
        <FormComponent flag={false} />
      </div>
      {/*  */}
    </div>
  );
};

export default Unf;
