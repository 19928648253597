export const ROUTES = {
  HOME: '/',
  NOTFOUND: '*',

  NEWS: '/news',
  ABOUT: '/about',

  ROZNITCYA: '/roznitcya',
  UNF: '/unf',
  ZUP: '/zup',
  V_OBLAKE: '/1c-v-oblake',
  LICENZIY: '/licenziy',
  ITS: '/its',

  // SERVICECENTER: '/service-center',
  // PRIVACYPOLICY: '/privacy-policy',
};
