import React from 'react';

import Hero from '../../Components/UI/Hero/Hero';
import Module from '../../Components/Module/Module';
import List from '../../Components/List/List';
import Title from '../../Components/UI/Title/Title';
import FormComponent from '../../Components/Form/Form';
import Price from '../../Components/Price/Price';

import HeroSectionBgImage from '../../Assets/Images/wave.svg';
import Hero_Image from '../../Assets/Images/1c-roznica.png';

import Price_Image from '../../Assets/Images/1c-roznica-1.png';

import Module_2_Image from '../../Assets/Images/yja.jpg';
import { Helmet } from 'react-helmet';

const Roznitcya = () => {
  const listData = [
    {
      title: 'Учет товаров:',
      subtitle:
        'Система позволяет вести учет товаров, контролировать остатки на складе, отслеживать движение товаров, а также автоматически обновлять информацию о наличии товаров.',
    },
    {
      title: 'Продажи и кассовые операции:',
      subtitle:
        '1С: Розница позволяет легко проводить продажи, оформлять заказы, принимать оплату и генерировать кассовые чеки. Также можно интегрировать систему с эквайринговыми системами и банковскими кредитами для обработки платежей.',
    },
    {
      title: 'Управление ценами:',
      subtitle:
        'Система позволяет управлять ценами на товары, устанавливать акции и скидки, автоматически пересчитывать стоимость товаров и отслеживать изменения в ценовой политике.',
    },
    {
      title: 'Учет финансов:',
      subtitle:
        '1С: Розница включает в себя функциональность для учета финансовых операций, включая ведение бухгалтерии, учет налогов, формирование финансовых отчетов и документов.',
    },
    {
      title: 'Анализ и отчетность:',
      subtitle:
        'Система предоставляет возможность анализировать данные о продажах, прибыли, остатках товаров и других аспектах бизнеса. Можно генерировать различные отчеты для принятия решений.',
    },
    {
      title: 'Управление клиентами:',
      subtitle:
        'Вы можете вести базу данных клиентов, отслеживать их покупки, устанавливать лояльность и предоставлять персонализированный сервис.',
    },
    {
      title: 'Управление складом:',
      subtitle:
        'Система помогает эффективно управлять складскими операциями, включая приемку товаров, перемещения между складами и инвентаризацию.',
    },
    {
      title: 'Интеграция с другими системами:',
      subtitle:
        '1С: Розница позволяет интегрировать свои данные с другими системами, такими как бухгалтерия, CRM, интернет-магазины и другие.',
    },
  ];

  const content = [
    {
      img: Price_Image,
      title: 'ОБЛАЧНАЯ 1С',
      subtitle: 'НА 1-2 ПОЛЬЗОВАТЕЛЯ',
      price: '4 600 тг/мес.',
      decs: `
        Полнофункциональная версия 1С.
        Работает через Интернет в облаке.
        Стоимость включает сопровождение-обновление.
      `,
    },
    {
      img: Price_Image,
      title: 'БАЗОВАЯ',
      subtitle: 'НА 1 ПОЛЬЗОВАТЕЛЯ',
      price: '25 900тг',
      decs: 'Готовое решение для небольших компании с любым видом деятельности',
    },
    {
      img: Price_Image,
      title: 'ПРОФ',
      subtitle: 'НА 1 ПОЛЬЗОВАТЕЛЯ',
      price: '77 800тг',
      decs: 'Универсальная программа, может быть адаптирована к любым особенностям учета.',
    },
  ];

  return (
    <div className="content">
      <Helmet>
        <link rel="canonical" href="https://itdoc.kz/roznitcya" />
        <title>1С Розница</title>
        <meta
          name="description"
          content={`1С Розница: мощное программное решение для управления вашим бизнесом в розничной торговле. Оптимизируйте учет, продажи и инвентаризацию с помощью 1С Розница. Упрощайте операции и повышайте эффективность вашего магазина.`}
        />
        <meta
          property="og:description"
          content={`1С Розница: мощное программное решение для управления вашим бизнесом в розничной торговле. Оптимизируйте учет, продажи и инвентаризацию с помощью 1С Розница. Упрощайте операции и повышайте эффективность вашего магазина.`}
        />
        <meta
          name="keywords"
          content="1С Розница, Розничная торговля, Управление магазином, Программа для учета товаров, Повышение эффективности бизнеса, Продажи и инвентаризация, Учет продаж, Оптимизация розничной деятельности, Программное решение для магазина
"
        />
      </Helmet>
      {/*  */}
      <Hero
        img={Hero_Image}
        bgImage={HeroSectionBgImage}
        title="1С:РОЗНИЦА"
        text={`"1С:Розница для Казахстана" обеспечивает возможность эффективно управлять розничными операциями, включая работу с эквайринговыми системами и банковскими кредитами.`}
        alt="1С РОЗНИЦА"
      />
      {/*  */}
      <Module
        alt="РОЗНИЦА"
        imgFlag={true}
        headlineText="Управляйте Вашим Бизнесом с Легкостью"
        title="Оптимизируйте Розничные Операции с 1С:Розница для Казахстана"
        text="С 1С:Розница для Казахстана, вы получаете доступ к мощным инструментам, которые помогут оптимизировать управление вашим розничным бизнесом. Наше решение обеспечивает полный контроль над инвентарем, учетом продаж, а также обеспечивает высокий уровень безопасности и точности данных."
        btnText="Купить"
      />
      {/*  */}
      <Module
        img={Module_2_Image}
        flexItem="flex-item"
        textAlignEnd="text-align-end"
        alt="МЫ ДАДИМ ВАМ 30 ДНЕЙ ДЛЯ ОЗНАКОМЛЕНИЯ"
        headlineText=""
        title="Интеграция «1С: Розница» с другими конфигурациями 1С"
        text="«Розница для Казахстана» работает автономно, независимо от других решений 1С. А вот если настроить двусторонний обмен с конфигурациями «Бухгалтерия для Казахстана» и «Управление торговлей для Казахстана», то они будут сотрудничать и брать на себя больше бизнес-задач."
        btnText="Купить"
      />
      {/*  */}
      <List data={listData} title="Какие процессы автоматизирует «1С: Розница 8 для Казахстана»" />
      {/*  */}
      <Price
        headline_title="1С:РОЗНИЦА ДЛЯ КАЗАХСТАНА"
        headline_subtitle="ЕСТЬ ТРИ ВЕРСИИ:"
        alt="1С РОЗНИЦА"
        btn_text="Купить"
        products={content}
      />
      {/*  */}
      <div style={{ marginBottom: '70px' }} className="container">
        <Title
          title="Есть вопросы или не уверены, что выбрать?"
          subtitle="Пожалуйста, заполните форму, и наш специалист свяжется с вами в самое ближайшее время, ответит на ваши вопросы и предложит наилучшие решения."
        />
        <FormComponent flag={false} />
      </div>
      {/*  */}
    </div>
  );
};

export default Roznitcya;
