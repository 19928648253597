import React from 'react';
import { NavLink } from 'react-router-dom';
import './LinksList.scss';

const LinksList = (props) => {
  return (
    <ul className={props.list_style}>
      {props.data.map((link, i) => (
        <li key={i} className={props.item_style}>
          <NavLink
            onClick={props.closeMenu}
            className={({ isActive }) =>
              `${isActive ? `${props.link_style} active` : `${props.link_style}`}`
            }
            to={link.link}
            download={link.download}
            target={link.target}>
            {link.name}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default LinksList;
