import React from 'react';

import Module from '../../Components/Module/Module';
import Title from '../../Components/UI/Title/Title';
import FormComponent from '../../Components/Form/Form';
import Table from '../../Components/Table/Table';
import { Helmet } from 'react-helmet';

import Module_2_SectionBgImage from '../../Assets/Images/wave-1.svg';
import Module_1_Image from '../../Assets/Images/1c-predpriyatiye-prof.png';

const Licenziy = () => {
  const tableData = [
    {
      row: {
        rowId: 1,
        row_1: '1С:Предприятие 8 ПРОФ. Клиентская лицензия на одно рабочее место.',
        row_2: 'Электронная поставка для Казахстана',
        row_3: '38 880тг',
      },
    },
    {
      row: {
        rowId: 2,
        row_1: '1С:Предприятие 8 ПРОФ. Клиентская лицензия на 5 рабочих мест.',
        row_2: 'Электронная поставка для Казахстана',
        row_3: '134 784тг',
      },
    },
    {
      row: {
        rowId: 3,
        row_1: '1С:Предприятие 8 ПРОФ. Клиентская лицензия на 10 рабочих мест.',
        row_2: 'Электронная поставка для Казахстана',
        row_3: '259 200тг',
      },
    },
    {
      row: {
        rowId: 4,
        row_1: '1С:Предприятие 8 ПРОФ. Клиентская лицензия на 20 рабочих мест.',
        row_2: 'Электронная поставка для Казахстана',
        row_3: '492 480тг',
      },
    },
    {
      row: {
        rowId: 5,
        row_1: '1С:Предприятие 8.3 ПРОФ. Лицензия на сервер (x86-64).',
        row_2: 'Электронная поставка для Казахстана',
        row_3: '518 400тг',
      },
    },
    {
      row: {
        rowId: 6,
        row_1: '1С:Предприятие 8.3 Сервер МИНИ на 5 подключений.',
        row_2: 'Электронная поставка для Казахстана',
        row_3: '103 680тг',
      },
    },
  ];

  const tableHeaderData = [{ colId: '#', col_1: 'Название', col_2: 'Поставка', col_3: 'Цена' }];

  return (
    <div className="content">
      <Helmet>
        <link rel="canonical" href="https://itdoc.kz/licenziy" />
        <title>Лицензии на сервер 1С</title>
        <meta
          name="description"
          content="Вы можете заказать у нас лицензии на сервер. Программную или аппаратную на ваш выбор. Звоните ☎+7 (708) 972-58-38."
        />
        <meta
          property="og:description"
          content="Вы можете заказать у нас лицензии на сервер. Программную или аппаратную на ваш выбор. Звоните ☎+7 (708) 972-58-38."
        />
        <meta name="keywords" content="1С сервер, лицензии на сервер" />
      </Helmet>
      <Module
        img={Module_1_Image}
        bgImage={Module_2_SectionBgImage}
        flexItem="flex-item"
        textAlignEnd="text-align-end"
        alt="Клиентские лицензии"
        headlineText=""
        title="Клиентские лицензии"
        text={`Клиентская лицензия 1С предназначена для увеличения количества рабочих станций для запуска программы 1С версии ПРОФ или КОРП на дополнительных рабочих станциях в рамках локальной сети. Лицензии "1С Предприятие 8" доступны для версий платформы "1С 8" 8.0, 8.1, 8.2 и 8.3.`}
        btnText="Купить"
      />
      <Table data={tableData} dataHeader={tableHeaderData} />
      <div style={{ marginBottom: '70px' }} className="container">
        <Title
          title="Есть вопросы или не уверены, что выбрать?"
          subtitle="Пожалуйста, заполните форму, и наш специалист свяжется с вами в самое ближайшее время, ответит на ваши вопросы и предложит наилучшие решения."
        />
        <FormComponent flag={false} />
      </div>
    </div>
  );
};

export default Licenziy;
