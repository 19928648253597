import React, { useEffect, useState } from 'react';
import style from './Price.module.scss';

import { Button } from 'react-bootstrap';
import Popup from '../Popup/Popup';
import FormComponent from '../Form/Form';

const Price = (props) => {
  const [productData, setProductData] = useState(props.products);
  // массив для хранения состояния модальных окон
  const [modalStates, setModalStates] = useState(new Array(props.products.length).fill(false));

  // Функция для открытия модального окна для конкретного продукта
  const openModal = (index) => {
    const updatedModalStates = [...modalStates];
    updatedModalStates[index] = true;
    setModalStates(updatedModalStates);
  };

  // Функция для закрытия модального окна для конкретного продукта
  const closeModal = (index) => {
    const updatedModalStates = [...modalStates];
    updatedModalStates[index] = false;
    setModalStates(updatedModalStates);
  };

  useEffect(() => {
    if (Array.isArray(props.products)) {
      setProductData(props.products);
    }
  }, [props.products]);

  return (
    <section className={style.price_wrapper}>
      <div className="container">
        <div className={style.wrapper}>
          <div className={style.headline}>
            <h3>{props.headline_title}</h3>
            <p>{props.headline_subtitle}</p>
          </div>
          <div className={style.items}>
            {/* item */}
            {productData.map((product, i) => (
              <div key={i} className={style.item}>
                <div className={style.img}>
                  <img src={product.img} alt={props.alt} />
                </div>
                <h4>{product.title}</h4>
                <div className={style.subtitle}>{product.subtitle}</div>
                <div className={style.price}>{product.price}</div>
                <p className={style.prod_description}>{product.decs}</p>
                {product.list
                  ? product.list.map((item, i) => (
                      <p key={i} className={style.prod_description}>
                        {item}
                      </p>
                    ))
                  : null}
                <div className={style.btn}>
                  <Button className={style.formBtn} variant="primary" onClick={() => openModal(i)}>
                    {' '}
                    {/* Открывает модальное окно для конкретного продукта */}
                    {props.btn_text}
                  </Button>
                </div>
                <Popup
                  contentComponent={<FormComponent flag={true} />}
                  show={modalStates[i]}
                  onHide={() => closeModal(i)}
                />
              </div>
            ))}
            {/* item */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Price;
