import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import style from './Header.module.scss';

// import PhoneNumber from '../UI/PhoneNumber/PhoneNumber';
import LinksList from '../UI/LinksList/LinksList';
import Logo from '../UI/Logo/Logo';
import DarkOverlay from '../UI/DarkOverlay/DarkOverlay';

import { ROUTES } from '../../utils/routes';

import whatsApp from '../../Assets/Icons/whatsapp.png';
import telegram from '../../Assets/Icons/telegram.png';
import onlinePay from '../../Assets/Icons/online-pay.png';
import Popup from '../Popup/Popup';
import PayBox from '../PayBox/PayBox';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Подписка на событие при монтировании компонента
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // Отписка от события при размонтировании компонента
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Обработчик клика по кнопке
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Плавная анимация прокрутки
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 200); // Если scrollTop больше 0, значит, страница прокручена вниз
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setTimeout(() => {
      setMenuOpen(!menuOpen);
    }, 0);
  };

  const headerLinksTop = [
    { name: 'О компании', link: ROUTES.ABOUT },
    {
      name: 'WhatsApp',
      link: 'https://wa.me/+77089725838?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C%20%D1%8F%20%D0%BF%D0%B8%D1%88%D1%83%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0%20itdoc.kz',
      target: '_blank',
    },
    { name: 'Позвонить: (+7 771 929 2144)', link: 'tel:+77719292144' },
    // { name: 'Новости', link: ROUTES.NEWS },
  ];

  const headerLinks = [
    { name: '1С Бухгалтерия', link: ROUTES.HOME },
    { name: 'Розница', link: ROUTES.ROZNITCYA },
    { name: 'Унф', link: ROUTES.UNF },
    { name: 'Зуп', link: ROUTES.ZUP },
    { name: '1С в облаке', link: ROUTES.V_OBLAKE },
    { name: 'Лицензии на сервер', link: ROUTES.LICENZIY },
    { name: '1С:ИТС', link: ROUTES.ITS },
  ];

  return (
    <>
      <header>
        <div
          className={style.header_top}
          style={isScrolled ? { transform: 'translateY(-100%)' } : {}}>
          <div className="container">
            <div className={style.headerTop_row}>
              {/* <PhoneNumber
                list_style="list_style-phone"
                item_style="item_style"
                link_style="link_style"
                item_link="https://wa.link/nud06u"
                text="+7 708 970 20 02"
                targetBlank="_blank"
                subtetxt="WhatsApp"
              /> */}

              <Link
                target="_blank"
                rel="noreferrer"
                to="https://itdoc.kz/rustdesk.zip"
                download="rustdesk.zip">
                Скачать RustDesk
              </Link>

              <LinksList
                list_style="list_style-linkList"
                item_style="item_style"
                link_style="link_style"
                data={headerLinksTop}
              />
            </div>
          </div>
        </div>
        <div
          className={`${style.header}`}
          style={isScrolled ? { transform: 'translateY(-54px)' } : {}}>
          <div className="container">
            <div className={style.header_row}>
              <Logo />
              <LinksList
                list_style="header-list_style-linkList"
                item_style="item_style"
                link_style="link_style"
                data={headerLinks}
              />
            </div>
          </div>
        </div>
        <div className={style.header_mobile}>
          {/* <div className={style.header_top}>
            <div className="container">
              <div className={style.header_top_row}>
                <PhoneNumber
                  list_style="list_style-phone"
                  item_style="item_style"
                  link_style="link_style"
                  item_link="https://wa.link/nud06u"
                  text="+7 708 970 20 02"
                  targetBlank="_blank"
                  subtetxt="WhatsApp"
                />
              </div>
            </div>
          </div> */}
          <div className={style.header_bottom}>
            <div className="container">
              <div className={style.header_bottom_row}>
                <Logo />
                <div onClick={toggleMenu} className={style.burger_menu}>
                  <i className="fa-solid fa-bars"></i>
                </div>
                {menuOpen && (
                  <div
                    className={`${style.header_mobile_menu_list} ${
                      menuOpen ? style.menu_open : ''
                    } `}>
                    <div onClick={toggleMenu} className={style.close_btn}>
                      <i className="fa-solid fa-xmark"></i>
                    </div>
                    <LinksList
                      list_style="mobile_header-list_style-linkList"
                      item_style="item_style"
                      link_style="link_style"
                      data={headerLinks.concat(headerLinksTop)}
                      closeMenu={toggleMenu}
                    />
                  </div>
                )}
                <DarkOverlay showOverlay={menuOpen} closeMenu={toggleMenu} />
              </div>
            </div>
          </div>
        </div>
      </header>
      <Outlet />

      <div className="socail_links">
        <ul>
          <li>
            <Link
              className="social_link"
              target="_blank"
              to="https://wa.me/+77089725838?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C%20%D1%8F%20%D0%BF%D0%B8%D1%88%D1%83%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0%20itdoc.kz">
              <img src={whatsApp} alt="whatsApp" />
            </Link>
          </li>
          <li>
            <Link to="https://t.me/itdockz" target="_blank" className="social_link">
              <img src={telegram} alt="telegram" />
            </Link>
          </li>
        </ul>
        <ul>
          <li style={{ maxWidth: '100%', maxHeight: '100%' }} onClick={() => setModalShow(true)}>
            <div className="arrow_box">
              <h1>Онлайн оплата</h1>
            </div>
            <div className="social_link">
              <img
                style={{ maxWidth: '60px', maxHeight: '60px' }}
                src={onlinePay}
                alt="onlinePay"
              />
            </div>
          </li>
          <Popup
            contentComponent={<PayBox />}
            show={modalShow}
            paybox={true}
            onHide={() => setModalShow(false)}
          />
        </ul>
      </div>

      {isVisible && (
        <ul className="scroll__top">
          <li onClick={scrollToTop} className="scroll__top-btn">
            <i className="fa-regular fa-circle-up"></i>
          </li>
        </ul>
      )}
    </>
  );
};

export default Header;
