import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TarifsPopup from '../../Components/Popup/TarifsPopup';
import style from './Tarifs.module.scss';
import { Button } from 'react-bootstrap';

import { FaRegCircleCheck } from 'react-icons/fa6';
import { FaRegCircleXmark } from 'react-icons/fa6';

const Tarifs = () => {
  const [showModal, setShowModal] = useState(null);

  const openModal = (buttonId) => {
    setShowModal(buttonId);
  };

  const closeModal = () => {
    setShowModal(null);
  };

  return (
    <section className="content">
      <div className={style.tarifs_wrapper}>
        <div className="container">
          <div className={style.wrapper}>
            <div className={style.tarifs}>
              <div className={style.tarif_item}>
                <h4 className="h1 mb-3">ИТС Техно</h4>

                <div className={style.variant_block}>
                  <h5 className={style.tarif_variant_title}>Количество баз данных:</h5>

                  <ul className={style.variant_list}>
                    <li className={style.variant_item}>
                      <FaRegCircleCheck className={style.iconCircleCheck} />
                      <span>1 база</span>
                    </li>
                  </ul>
                </div>

                <div className={style.tarif_price}>Стоимость услуг</div>

                <div className={style.variant_block}>
                  <h5 className={style.tarif_variant_title}>Тарифы:</h5>

                  <ul className={style.variant_list}>
                    <li className={style.variant_item}>
                      <FaRegCircleXmark className={style.iconXmark} />
                      <span>
                        3 месяца: <strong></strong>
                      </span>
                    </li>
                    <li className={style.variant_item}>
                      <FaRegCircleCheck className={style.iconCircleCheck} />
                      <span>
                        6 месяцев: <strong>34 000 тг</strong>
                      </span>
                    </li>
                    <li className={style.variant_item}>
                      <FaRegCircleCheck className={style.iconCircleCheck} />
                      <span>
                        12 месяцев: <strong>64 000 тг</strong>
                      </span>
                    </li>
                  </ul>
                </div>

                <div className={style.tarif_btn}>
                  <Button
                    className={style.tarif_formBtn}
                    variant="primary"
                    onClick={() => openModal(1)}>
                    Заказать
                  </Button>
                  <Link
                    to={'https://itdoc.kz/Тарифы-итс.pdf'}
                    download="Тарифы-итс.pdf"
                    target="_blank"
                    className={`${style.price_downloadBtn} btn btn-warning`}>
                    Скачать прайс
                  </Link>
                </div>

                {showModal === 1 && <TarifsPopup showModal={showModal} closeModal={closeModal} />}
              </div>

              <div className={style.tarif_item}>
                <h4 className="h1 mb-3">ИТС Проф</h4>

                <div className={style.variant_block}>
                  <h5 className={style.tarif_variant_title}>Количество баз данных:</h5>

                  <ul className={style.variant_list}>
                    <li className={style.variant_item}>
                      <FaRegCircleCheck className={style.iconCircleCheck} />
                      <span>до 2 баз</span>
                    </li>
                  </ul>
                </div>

                <div className={style.tarif_price}>Стоимость услуг</div>

                <div className={style.variant_block}>
                  <h5 className={style.tarif_variant_title}>Тарифы:</h5>

                  <ul className={style.variant_list}>
                    <li className={style.variant_item}>
                      <FaRegCircleCheck className={style.iconCircleCheck} />
                      <span>
                        3 месяца: <strong>36 000 тг</strong>
                      </span>
                    </li>
                    <li className={style.variant_item}>
                      <FaRegCircleCheck className={style.iconCircleCheck} />
                      <span>
                        6 месяцев: <strong>69 000 тг</strong>
                      </span>
                    </li>
                    <li className={style.variant_item}>
                      <FaRegCircleCheck className={style.iconCircleCheck} />
                      <span>
                        12 месяцев: <strong>130 000 тг</strong>
                      </span>
                    </li>
                  </ul>
                </div>

                <div className={style.tarif_btn}>
                  <Button
                    className={style.tarif_formBtn}
                    variant="primary"
                    onClick={() => openModal(2)}>
                    Заказать
                  </Button>
                  <Link
                    to={'https://itdoc.kz/Тарифы-итс.pdf'}
                    download="Тарифы-итс.pdf"
                    target="_blank"
                    className={`${style.price_downloadBtn} btn btn-warning`}>
                    Скачать прайс
                  </Link>
                </div>

                {showModal === 2 && <TarifsPopup showModal={showModal} closeModal={closeModal} />}
              </div>

              <div className={style.tarif_item}>
                <h4 className="h1 mb-3">ИТС Корп</h4>

                <div className={style.variant_block}>
                  <h5 className={style.tarif_variant_title}>Количество баз данных:</h5>

                  <ul className={style.variant_list}>
                    <li className={style.variant_item}>
                      <FaRegCircleCheck className={style.iconCircleCheck} />
                      <span>до 10 баз</span>
                    </li>
                  </ul>
                </div>

                <div className={style.tarif_price}>Стоимость услуг</div>

                <div className={style.variant_block}>
                  <h5 className={style.tarif_variant_title}>Тарифы:</h5>

                  <ul className={style.variant_list}>
                    <li className={style.variant_item}>
                      <FaRegCircleCheck className={style.iconCircleCheck} />
                      <span>
                        3 месяца: <strong>90 000 тг</strong>
                      </span>
                    </li>
                    <li className={style.variant_item}>
                      <FaRegCircleCheck className={style.iconCircleCheck} />
                      <span>
                        6 месяцев: <strong>170 000 тг</strong>
                      </span>
                    </li>
                    <li className={style.variant_item}>
                      <FaRegCircleCheck className={style.iconCircleCheck} />
                      <span>
                        12 месяцев: <strong>325 000 тг</strong>
                      </span>
                    </li>
                  </ul>
                </div>

                <div className={style.tarif_btn}>
                  <Button
                    className={style.tarif_formBtn}
                    variant="primary"
                    onClick={() => openModal(3)}>
                    Заказать
                  </Button>
                  <Link
                    to={'https://itdoc.kz/Тарифы-итс.pdf'}
                    download="Тарифы-итс.pdf"
                    target="_blank"
                    className={`${style.price_downloadBtn} btn btn-warning`}>
                    Скачать прайс
                  </Link>
                </div>

                {showModal === 3 && <TarifsPopup showModal={showModal} closeModal={closeModal} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tarifs;
