import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import style from './Footer.module.scss';

import footer_logo from '../../Assets/Images/footer-logo.svg';
import instagram from '../../Assets/Icons/i.svg';

const Footer = () => {
  return (
    <footer className={style.footer_wrapper}>
      <div className="container">
        <div className={style.wrapper}>
          <div className={style.img}>
            <img src={footer_logo} alt="footer logo" />
          </div>
          <div className={style.copyright}>
            <p>© IT Doc 2023</p>
            <p>Все права защищены</p>
            <small style={{ fontSize: '10px' }}>
              This site is protected by reCAPTCHA and the Google
              <Link target="_blank" to="https://policies.google.com/privacy">
                {' '}
                Privacy Policy
              </Link>{' '}
              and
              <Link target="_blank" to="https://policies.google.com/terms">
                {' '}
                Terms of Service
              </Link>{' '}
              apply.
            </small>
          </div>
          <ul className={style.social_links}>
            <li>
              <NavLink
                target="_blank"
                to={'https://instagram.com/itdoc.kz?igshid=MzRlODBiNWFlZA=='}>
                <img src={instagram} alt="instagram" />
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
