import React from 'react';
import style from './Title.module.scss';

const Title = (props) => {
  return (
    <section className={style.title_wrapper}>
      <div className={style.wrapper}>
        <h4>{props.title}</h4>
        <p>{props.subtitle}</p>
      </div>
    </section>
  );
};

export default Title;
