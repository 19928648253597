import React from 'react';
import { Helmet } from 'react-helmet';

import Hero from '../../Components/UI/Hero/Hero';
import Module from '../../Components/Module/Module';
import Price from '../../Components/Price/Price';
import Title from '../../Components/UI/Title/Title';
import FormComponent from '../../Components/Form/Form';

import bugh_1C from '../../Assets/Images/1c-buhgalteria.png';
import HeroSectionBgImage from '../../Assets/Images/wave.svg';

import Module_1_SectionBgImage from '../../Assets/Images/wave-1.svg';
import Module_1_Image from '../../Assets/Images/reporting-img.png';

import Module_2_Image from '../../Assets/Images/tenge.jpg';

import Module_3_Image from '../../Assets/Images/analiz.png';

import product_image from '../../Assets/Images/box.png';
import product_image_1 from '../../Assets/Images/cloud-fr.png';

const HomePage = () => {
  const content = [
    {
      img: product_image_1,
      title: 'ОБЛАЧНАЯ 1С',
      subtitle: 'НА 1-2 ПОЛЬЗОВАТЕЛЯ',
      price: '4 600 тг/мес.',
      decs: `
        Полнофункциональная версия 1С.
        Работает через Интернет в облаке.
        Стоимость включает сопровождение-обновление.
      `,
    },
    {
      img: product_image,
      title: 'БАЗОВАЯ',
      subtitle: 'НА 1 ПОЛЬЗОВАТЕЛЯ',
      price: '25 900тг',
      decs: 'Готовое решение для небольших компании с любым видом деятельности',
    },
    {
      img: product_image,
      title: 'ПРОФ',
      subtitle: 'НА 1 ПОЛЬЗОВАТЕЛЯ',
      price: '77 800тг',
      decs: 'Универсальная программа, может быть адаптирована к любым особенностям учета.',
    },
    {
      img: product_image,
      title: 'СЕТЕВАЯ',
      subtitle: 'НА 5 ПОЛЬЗОВАТЕЛЕЙ',
      price: '181 500тг',
      decs: 'Комплект на 5 пользователей по выгодной цене.',
    },
  ];

  return (
    <>
      <div>
        <div className="content">
          <Helmet>
            <link rel="canonical" href="https://itdoc.kz/" />
            <title>Программы 1С Бухгалтерия и 1С Предприятие</title>
            <meta
              name="description"
              content="1С Бухгалтерия и 1С Предприятие от 1С Франчайзи в Казахстане. Продажа, установка, настройка 1С, обучение пользователей. Звоните ☎+7 (708) 972-58-38."
            />
            <meta
              property="og:description"
              content="1С Бухгалтерия и 1С Предприятие от 1С Франчайзи в Казахстане. Продажа, установка, настройка 1С, обучение пользователей. Звоните ☎+7 (708) 972-58-38."
            />
            <meta
              name="keywords"
              content="Купить 1С, Купить 1С в Алматы, Программы 1С, купить программу 1С"
            />
          </Helmet>
          <Hero
            img={bugh_1C}
            bgImage={HeroSectionBgImage}
            title="1С:БУХГАЛТЕРИЯ"
            text="Автоматизация бухгалтерии с помощью 1С: Ваш путь к финансовой надежности"
            alt="1С БУХГАЛТЕРИЯ"
          />
          <Module
            img={Module_1_Image}
            bgImage={Module_1_SectionBgImage}
            alt="Бухгалтерия"
            headlineText="ТЕПЕРЬ РАБОТЫ БУДЕТ В ДВА РАЗА МЕНЬШЕ"
            title="Новый уровень комфорта
        для бухгалтера"
            text="Новое поколение “1С:Бухгалтерия” для Казахстана позволяет, используя ЭЦП, автоматически: отправлять и принимать электронные счета-фактуры, осуществлять прямой обмен с банком, вести электронные трудовые книжки, сдавать электронную налоговую отчетность и многое другое, что сократит работу бухгалтера и сделает ее комфортней."
            btnText="Купить"
          />
          <Module
            img={Module_2_Image}
            flexItem="flex-item"
            textAlignEnd="text-align-end"
            alt="МЫ ДАДИМ ВАМ 30 ДНЕЙ ДЛЯ ОЗНАКОМЛЕНИЯ"
            headlineText="МЫ ДАДИМ ВАМ 30 ДНЕЙ ДЛЯ ОЗНАКОМЛЕНИЯ"
            title="Попробуйте совершенно
        бесплатно"
            text="Вы сможете бесплатно использовать все функции новейшей версии “1С:Бухгалтерия 8 для Казахстана”, в режиме онлайн уже через 2 минуты. Нужно лишь оставить заявку на нашем сайте! Если сервис вам понравится, продолжайте работу в этой облачной версии по ежемесячной подписке, либо приобретайте “коробочную” версию продукта и мы перенесем ваши данные из “облака”. Никакого риска или больших вложений наперед!"
            btnText="Купить"
          />
          <Price
            headline_title="1С:БУХГАЛТЕРИЯ 8.3 ДЛЯ КАЗАХСТАНА"
            headline_subtitle="ЕСТЬ ЧЕТЫРЕ ВЕРСИИ:"
            alt="1С Бухгалтерия"
            btn_text="Купить"
            products={content}
          />
          <div style={{ marginBottom: '70px' }} className="container">
            <Title
              title="Есть вопросы или не уверены, что выбрать?"
              subtitle="Пожалуйста, заполните форму, и наш специалист свяжется с вами в самое ближайшее время, ответит на ваши вопросы и предложит наилучшие решения."
            />
            <FormComponent flag={false} />
          </div>
          <Module
            img={Module_3_Image}
            alt="Возможности 1С"
            headlineText=""
            title="Весь учет в одной программе"
            text="Никогда еще бухгалтерская программа не умела делать так много. 1С:Бухгалтерия позволяет вести учет различных видов деятельности, со всевозможными схемами налогообложения. Для работы в ней не требуется специальных знаний. Вы получите качественно новый уровень автоматизации учета."
            btnText="Купить"
          />
        </div>
      </div>
    </>
  );
};

export default HomePage;
