import { useEffect } from 'react';
import AppRoutes from './Routes/Routes';
import { loadReCaptcha } from 'recaptcha-v3-react-function-async';

function App() {
  useEffect(() => {
    loadReCaptcha(process.env.REACT_APP_RECAPTCHA_SITE_KEY)
      .then(() => {
        console.log('ReCaptcha loaded');
      })
      .catch((e) => {
        console.error('Error when load ReCaptcha', e);
      });
  }, []);

  return <AppRoutes />;
}

export default App;
