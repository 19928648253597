import React from 'react';

import Hero from '../../Components/UI/Hero/Hero';
import Module from '../../Components/Module/Module';
import Title from '../../Components/UI/Title/Title';

import HeroIMage from '../../Assets/Images/cloud.svg';
import HeroSectionBgImage from '../../Assets/Images/wave.svg';

import Module_1_Image from '../../Assets/Images/cloud-1c-1.jpg';

import Module_2_Image from '../../Assets/Images/tenge.jpg';
import Cloud from '../../Assets/Images/cloud-2.png';
import mobilnost from '../../Assets/Images/1s-v-oblake.jpg';
import Price from '../../Components/Price/Price';
import FormComponent from '../../Components/Form/Form';
import { Helmet } from 'react-helmet';

const Oblako = () => {
  const textContent = [
    {
      text: 'Кризисы, эпидемии, колебания курсов валют и другие непредвиденные внешние обстоятельства могут оказать серьезное влияние на бизнес. В таких ситуациях особенно остро встает вопрос "как сэкономить".',
    },
    {
      text: 'Миграция базы данных "1С" в облако - редкий пример экономии бюджета компании без потери качества.',
    },
    {
      text: 'Когда речь идет о выборе между программой в обычной "коробке" и облачной "1С", многие считают, что платить провайдеру ежемесячно бессмысленно, а дешевле купить все один раз. Но установка программ на компьютер требует определенных финансовых вложений, таких как покупка мощного компьютера, обслуживание офисных серверов, работа ИТ-специалиста, покупка дополнительных лицензий и регулярные платежи за обновление программ.',
    },
    {
      text: 'В "облаке" "1С" обновления уже включены. Кроме того, нет необходимости задумываться о мощности компьютера. Облачная версия "1С" работает даже на самом простом ноутбуке. Единственное требование - хорошее подключение к Интернету.',
    },
  ];

  const content = [
    {
      img: HeroIMage,
      title: 'РАСШИРЕННЫЙ',
      subtitle: 'До 10 баз на 5 пользователей',
      price: '13 000 тг/мес.',
      decs: `Приложения`,
      list: [
        '1С:Бухгалтерия для Казахстана.',
        '1С:ЗУП для Казахстана.',
        '1С:Розница для Казахстана.',
      ],
    },
    {
      img: HeroIMage,
      title: 'ОПТИМАЛЬНЫЙ',
      subtitle: 'До 2 баз на 3 пользователя',
      price: '11 000тг',
      decs: `Приложения`,
      list: [
        '1С:Бухгалтерия для Казахстана.',
        '1С:ЗУП для Казахстана.',
        '1С:Розница для Казахстана.',
      ],
    },
    {
      img: HeroIMage,
      title: 'СТАРТОВЫЙ',
      subtitle: '1 база на 1 пользователя',
      price: '4 600тг',
      decs: `Приложения`,
      list: [
        '1С:Бухгалтерия для Казахстана.',
        '1С:ЗУП для Казахстана.',
        '1С:Розница для Казахстана.',
      ],
    },
    {
      img: HeroIMage,
      title: 'УНФ',
      subtitle: '1 база на 5 пользователей',
      price: '6 400тг',
      decs: `Приложения`,
      list: ['1С:УНФ'],
    },
  ];

  return (
    <div className="content">
      <Helmet>
        <link rel="canonical" href="https://itdoc.kz/1c-v-oblake" />
        <title>1С в облаке</title>
        <meta
          name="description"
          content={`Облачные сервисы "1С" прочно вошли в нашу жизнь и все больше становятся частью бизнеса. Базы данных "1С" в облаке - разумное решение для тех, кто ценит стоимость и безопасность данных.`}
        />
        <meta
          property="og:description"
          content={`Облачные сервисы "1С" прочно вошли в нашу жизнь и все больше становятся частью бизнеса. Базы данных "1С" в облаке - разумное решение для тех, кто ценит стоимость и безопасность данных.`}
        />
        <meta name="keywords" content="1C в облаке, 1С Fresh, купить 1С в облаке, облачный 1С" />
      </Helmet>
      {/*  */}
      <Hero
        img={HeroIMage}
        bgImage={HeroSectionBgImage}
        title="1С В ОБЛАКЕ"
        text={`Облачные сервисы "1С" прочно вошли в нашу жизнь и все больше становятся частью нашего бизнеса. Базы данных "1С" в облаке - разумное решение для тех, кому важны стоимость и безопасность данных.`}
        alt="1С В ОБЛАКЕ"
      />
      {/*  */}
      <Module
        img={Module_1_Image}
        alt="Бухгалтерия"
        headlineText="«1С» облако"
        title="Что такое «1С» облако?"
        text={`По всем параметрам и функциям идентична "1С", которая арендуется у компании-провайдера за ежемесячную плату. Единственное отличие заключается в том, что все данные хранятся не на жестком диске компьютера, а на защищенном удаленном сервере.`}
        btnText="Купить"
      />
      {/*  */}
      <Module
        flexItem="flex-item"
        textAlignEnd="text-align-end"
        text="Облачная система 1С избавляет от необходимости тратить время наприобретение,активацию, установку и регулярное обновлениепрограмм.Пользователь освобождается от всех этих забот,поскольку управление приложениями,их обновлениеи резервное копирование данных берут на себя специалисты ИТ-компании."
        btnText="Купить"
      />
      {/*  */}
      <Module
        text={`Бухгалтерия "1С" Cloud и другие арендные продукты фирмы "1С" являются идеальным решением для тех, кто хочет использовать весь функционал системы "1С", но нежелает тратить деньги на собственные серверы,оборудование и персонал.`}
        btnText="Купить"
      />
      {/*  */}
      <Title title="Плюсы «1С» в облаке" />
      {/*  */}
      <Module
        img={Module_2_Image}
        flexItem="flex-item"
        textAlignEnd="text-align-end"
        alt="Главное – это, конечно, экономия."
        headlineText="1. Главное – это, конечно, экономия."
        textContent={textContent}
        btnText="Купить"
      />
      {/*  */}
      <Module
        img={Cloud}
        alt="Быстрый старт с «1С Облако»"
        headlineText="2. Быстрый старт с «1С Облако»"
        text={`При покупке "коробки" необходимо заказать решение, оплатить его в банке и дождаться приезда специалиста для установки. Это занимает несколько дней. Для начала работы с облачной "1С" достаточно ввести свой e-mail и начать работу с тестовым периодом - 2 минуты и вы готовы к работе! Если вас устраивает облачная версия "1С", вы можете легко перейти на платный доступ. В этом случае все данные будут полностью защищены.`}
        btnText="Купить"
      />
      {/*  */}
      <Module
        img={mobilnost}
        flexItem="flex-item"
        textAlignEnd="text-align-end"
        alt="Мобильность"
        headlineText="3. Мобильность"
        text={`Еще одно важное преимущество "облачной" "1С" - мобильность. Вы можете получить доступ к данным компании из любой точки мира, в любое время, на любом устройстве, если у вас есть доступ в Интернет. Это идеальное решение для взаимодействия с коллегами при удаленной работе.`}
        btnText="Купить"
      />
      {/*  */}
      <Price
        headline_title="1С:ОБЛАКО"
        headline_subtitle="ЕСТЬ ЧЕТЫРЕ ТАРИФА:"
        alt="1С:ОБЛАКО"
        btn_text="Купить"
        products={content}
      />
      {/*  */}
      <div style={{ marginBottom: '70px' }} className="container">
        <Title
          title="Есть вопросы или не уверены, что выбрать?"
          subtitle="Пожалуйста, заполните форму, и наш специалист свяжется с вами в самое ближайшее время, ответит на ваши вопросы и предложит наилучшие решения."
        />
        <FormComponent flag={false} />
      </div>
      {/*  */}
    </div>
  );
};

export default Oblako;
