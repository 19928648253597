import React, { useEffect, useState } from 'react';

import './Table.scss';

const Table = (props) => {
  const [tableData, setTableData] = useState([]);
  const [tableHeaderData, setTableHeaderData] = useState([]);

  useEffect(() => {
    if (Array.isArray(props.data)) {
      setTableData(props.data);
    }
    if (Array.isArray(props.dataHeader)) {
      setTableHeaderData(props.dataHeader);
    }
  }, [props.data, props.dataHeader]);

  return (
    <section className="pt-5 pb-5 container">
      <table className="table">
        <thead>
          {tableHeaderData.map((item, i) => (
            <tr key={i}>
              <th scope="col">{item.colId}</th>
              <th scope="col" className={`${props.flag === true ? 'tarif_bg_color_1' : ''}`}>
                {item.col_1}
              </th>
              <th scope="col" className={`${props.flag === true ? 'tarif_bg_color_2' : ''}`}>
                {item.col_2}
              </th>
              <th scope="col" className={`${props.flag === true ? 'tarif_bg_color_3' : ''}`}>
                {item.col_3}
              </th>
            </tr>
          ))}
        </thead>
        <tbody className="table-group-divider" style={{ fontSize: '14px' }}>
          {tableData.map((item, index) => (
            <tr key={index}>
              <th scope="row" style={{ padding: '10px 30px' }}>
                {item.row && item.row.rowId}
              </th>
              <td className={`${props.flag === true ? 'tarif_bg_color_1' : ''}`}>
                {item.row && item.row.row_1}
              </td>
              <td className={`${props.flag === true ? 'tarif_bg_color_2' : ''}`}>
                {item.row && item.row.row_2}
              </td>
              <td className={`${props.flag === true ? 'tarif_bg_color_3' : ''}`}>
                {item.row && item.row.row_3}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default Table;
