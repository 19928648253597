import React from 'react';
import { Link } from 'react-router-dom';
import './PhoneNumber.scss';

const PhoneNumber = (props) => {
  return (
    <ul className={props.list_style}>
      <li className={props.item_style}>
        <Link target={props.targetBlank} className={props.link_style} to={props.item_link}>
          {`${props.text}`} <span>{props.subtetxt}</span>
        </Link>
      </li>
    </ul>
  );
};

export default PhoneNumber;
