import React from 'react';
import { NavLink } from 'react-router-dom';

import './Logo.scss';

import { ROUTES } from '../../../utils/routes';

import logo from '../../../Assets/Images/LOGO_3.png';

const Logo = () => {
  return (
    <NavLink to={ROUTES.HOME} className="logo-block">
      <img className="logo-image" src={logo} alt="logo" />
      <div className="logo-text-block">
        <span className="logo-text">IT DOC. 1С:Франчайзинг</span>
        <span className="logo-subtext">Бизнес программы нового поколения</span>
      </div>
    </NavLink>
  );
};

export default Logo;
