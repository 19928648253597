import React from 'react';
import style from './Alert.module.scss';

const Alert = (props) => {
  return (
    <section className={style.Alert_wrapper}>
      <div className="container">
        <div className={style.wrapper}>
          <div className="alert alert-success" role="alert">
            <h4 className="alert-heading">{props.title}</h4>
            <p>{props.text}</p>
            <hr />
            <p className="mb-0">{props.bottom_text}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Alert;
