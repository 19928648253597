import React from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../utils/routes';

import './NotFound.scss';

const NotFound = () => {
  return (
    <section className={`notFound content`}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 align-self-center">
            <img
              className="w-100"
              src="https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif"
              alt="Not Found"
            />
          </div>
          <div className="col-md-6 align-self-center">
            <h1>404</h1>
            <h2>О! Вы заблудились.</h2>
            <p>
              Страница, которую вы ищете, не существует. Как Вы сюда попали - загадка. Но вы можете
              нажать кнопку ниже, чтобы вернуться на главную страницу.
            </p>
            <Link to={ROUTES.HOME} className="btn green">
              НА ГЛАВНУЮ
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
