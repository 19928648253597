import React from 'react';
import style from './List.module.scss';
import Title from '../UI/Title/Title';

const List = (props) => {
  return (
    <section className={style.list_wrapper}>
      <div className="container">
        <Title title={props.title} />
        <div className={style.wrapper}>
          <ol className={`${style.list_group} list-group list-group-numbered`}>
            {props.data.map((item, i) => (
              <li key={i} className={`${style.list_item} list-group-item`}>
                <strong>{item.title}</strong> <span>{item.subtitle}</span>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </section>
  );
};

export default List;
